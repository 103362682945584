define('aia-travel/components/mega-menu/calendar', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    init: function init() {
      this._super();
      this.set('availableYear', this.getAvailableMonthYear().years);
      this.set('availableMonths', this.getAvailableMonthYear().months);
      this.set('config', this.makeCalendar());
      this.checkYear();
      this.checkDate();
    },
    getAvailableMonthYear: function getAvailableMonthYear() {
      var years = [];
      var months = [];
      this.get('availableDate').forEach(function (date) {
        if ((0, _moment.default)(date).isSameOrAfter((0, _moment.default)(), 'month')) {
          if (years.indexOf((0, _moment.default)(date).year().toString()) <= -1) {
            years.push((0, _moment.default)(date).year().toString());
          }
          if (months.indexOf((0, _moment.default)(date).format('MMM')) <= -1) {
            months.push((0, _moment.default)(date).format('MMM'));
          }
        }
      });
      return { years: years.sort(), months: months };
    },

    makeCalendar: function makeCalendar() {
      var data = [],
          availableYear = this.get('availableYear');

      for (var i = 0; i < 3; i++) {
        data[i] = {
          year: (0, _moment.default)().add(i, 'years').format('YYYY'),
          disabled: availableYear.indexOf((0, _moment.default)().add(i, 'years').format('YYYY')) > -1 ? false : true,
          checked: false
        };
        data[i]['months'] = [];
        for (var j = 0; j < _moment.default.monthsShort().length; j++) {
          var dateValue = (0, _moment.default)([(0, _moment.default)().add(i, 'years').format('YYYY'), j, '01']).format('MM/DD/YYYY');
          data[i]['months'][j] = {
            name: _moment.default.monthsShort()[j],
            value: dateValue,
            available: (0, _moment.default)(dateValue).isSameOrAfter((0, _moment.default)().format('MM/DD/YYYY'), 'month'),
            disabled: this.get('availableDate').indexOf(dateValue) > -1 ? false : true,
            checked: false
          };
        }
      }
      return data;
    },
    checkDate: function checkDate() {
      var _this = this;

      var landingView = this.get('availableYear').length ? this.get('availableYear')[this.get('availableYear').length - 1] : null;
      landingView = this.get('megamenu.selectedYear').length > 1 ? landingView : null;

      if (this.get('megamenu.selectedDate').length) {
        this.get('megamenu.selectedDate').forEach(function (date) {
          var result = _this.get('config').filter(function (item) {
            var monthResult = item.months.filter(function (month) {
              if (_this.get('megamenu.selectedDate').indexOf(month.value) > -1) {
                Ember.set(month, 'checked', true);
              } else {
                Ember.set(month, 'checked', false);
              }
              if (landingView === item.year) {
                Ember.set(month, 'disabled', _this.get('availableMonths').indexOf(month.name) <= -1);
              } else {
                Ember.set(month, 'disabled', _this.get('availableDate').indexOf(month.value) <= -1);
              }
              return true;
            });
            Ember.set(item, 'months', monthResult);
            return true;
          });
          _this.set('config', result);
        });
      } else {
        var result = this.get('config').filter(function (item) {

          var monthResult = [];
          if (landingView === item.year) {
            monthResult = item.months.filter(function (month) {
              Ember.set(month, 'disabled', _this.get('availableMonths').indexOf(month.name) <= -1);
              Ember.set(month, 'checked', false);
              return true;
            });
          } else {
            monthResult = item.months.filter(function (month) {
              Ember.set(month, 'disabled', _this.get('availableDate').indexOf(month.value) <= -1);
              Ember.set(month, 'checked', false);
              return true;
            });
          }

          Ember.set(item, 'months', monthResult);
          return true;
        });
        this.set('config', result);
      }
    },
    checkYear: function checkYear() {
      var _this2 = this;

      if (this.get('megamenu.selectedDate').length && this.get('megamenu.selectedYear').length < 2) {
        var year = (0, _moment.default)(this.get('megamenu.selectedDate')[0]).year().toString();
        this.get('config').forEach(function (item) {
          if (item.year === year) {
            Ember.set(item, 'checked', true);
          } else {
            Ember.set(item, 'checked', false);
          }
          return true;
        });
      } else {

        var years = this.get('availableYear');

        years = this.get('megamenu.selectedYear').length ? this.get('megamenu.selectedYear') : years.length ? years : [];
        this.get('config').forEach(function (item) {
          if (years.indexOf(item.year) > -1) {
            _this2.set('megamenu.selectedYear', years);
            Ember.set(item, 'checked', true);
          } else {
            Ember.set(item, 'checked', false);
          }
        });
      }
    },
    actions: {
      dateSelected: function dateSelected(val) {
        if (this.get('megamenu.selectedDate').indexOf(val) > -1) {
          var result = this.get('megamenu.selectedDate').filter(function (item) {
            return item !== val;
          });
          this.set('megamenu.selectedDate', result);
        } else {
          this.set('megamenu.selectedDate', [val]);
        }
        if (this.get('megamenu.selectedDate').length && this.get('routing.currentRouteName') !== 'destinations') {
          this.get('routing').transitionTo('destinations');
        }
        this.checkDate();
      },
      yearChange: function yearChange(val) {
        var _this3 = this;

        this.get('config').forEach(function (item) {
          if (item.year === val) {
            _this3.set('megamenu.selectedYear', [item.year]);
            Ember.set(item, 'checked', true);
          } else {
            Ember.set(item, 'checked', false);
          }
        });
        if (this.get('routing.currentRouteName') !== 'destinations') {
          this.get('routing').transitionTo('destinations');
        }
        this.set('megamenu.selectedDate', []);
        this.checkDate();
      }
    }
  });
});