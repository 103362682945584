define('aia-travel/components/share-buttons', ['exports', 'ember-share-buttons/templates/components/share-buttons'], function (exports, _shareButtons) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;


  var defaultNetworks = ['twitter', 'facebook', 'pinterest', 'email'];

  exports.default = Ember.Component.extend({
    classNames: ['share-buttons'],
    fastboot: Ember.inject.service(),
    isFastBoot: computed.readOnly('fastboot.isFastBoot'),
    layout: _shareButtons.default,
    config: null,
    tagName: 'ul',
    routing: Ember.inject.service('-routing'),

    didReceiveAttrs: function didReceiveAttrs() {
      var config = this.get('config');
      var networks = void 0;
      if (config && config.networks) {
        networks = config.networks.map(expandNetworkConfig).filter(function (n) {
          return defaultNetworks.indexOf(n.name) !== -1;
        });
      } else {
        networks = defaultNetworks.map(expandNetworkConfig);
      }
      this.set('networks', networks);
    },


    pageTitle: computed('routing.currentRouteName', function () {
      if (!this.get('isFastBoot')) {
        var current = this.get('routing.currentRouteName');
        var Bodytxt = Ember.$('.content-wrapper p').text().split('.');
        var result = '';
        if (current === "index") {
          result = 'Architectural Adventures is the official travel program of The American Institute of Architects';
        } else if (current === "destinations") {
          result = 'Architectural Adventures is the official travel program of The American Institute of Architects';
        } else if (current === "experts") {
          result = 'Architectural Adventures is the official travel program of The American Institute of Architects';
        } else if (current === "destination") {
          var strong = Ember.$('.content-wrapper p:has(strong)');
          if (!strong) {
            result = Ember.$('.banner-title h1').text() + ': ' + Ember.$('.smaller-page-intro h2').text();
          } else {
            result = Ember.$('.banner-title h1').text() + ': ' + Ember.$('.smaller-page-intro h2').text();
          }
        } else if (current === "expert") {
          if (Bodytxt[0].length < 10) {
            result = 'Meet ' + Ember.$('.smaller-page-intro h2').text();
          } else {
            result = 'Meet ' + Ember.$('.smaller-page-intro h2').text();
          }
        }
        result = result.replace(/&/g, "and");
        return encodeURIComponent(result);
      }
    }).volatile(),

    pageDescription: computed('ogDescription', function () {
      if (!this.get('isFastBoot')) {
        var current = this.get('routing.currentRouteName');
        var Bodytxt = Ember.$('.content-wrapper p').text().split('.');
        var Hometxt = Ember.$.trim(Ember.$('.intro-txt').text()).split('.');
        var result = '';
        if (current === "index") {
          result = Hometxt[0] + '.' + '\n' + '\n' + 'To learn more, please visit: ' + this.url();
        } else if (current === "destinations") {
          result = "Architectural Adventures carefully selects destinations that stand out for the global significance of their architecture and culture. Whether you're an architectural enthusiast or an intellectually curious traveler, our trips are designed to expand your understanding and appreciation of the built environment and its place in the wider world." + '\n' + '\n' + 'To learn more, please visit: ' + this.url();
        } else if (current === "experts") {
          result = Ember.$.trim(Ember.$('.intro-txt').text()) + '\n' + '\n' + 'To learn more, please visit: ' + this.url();
        } else if (current === "destination") {
          var strong = Ember.$('.content-wrapper p:has(strong)');
          strong = strong.length > 1;
          if (!strong) {
            Bodytxt[0] = Bodytxt[0].trim();
            result = Ember.$('.banner-title h1').text() + ': ' + Ember.$('.smaller-page-intro h2').text() + '\n' + '\n' + Bodytxt[0] + '.' + '\n' + '\n' + 'To learn more, please visit: ' + this.url();
          } else {
            Bodytxt[1] = Bodytxt[1].trim();
            result = Ember.$('.banner-title h1').text() + ': ' + Ember.$('.smaller-page-intro h2').text() + '\n' + '\n' + Bodytxt[1] + '.' + '\n' + '\n' + 'To learn more, please visit: ' + this.url();
          }
        } else if (current === "expert") {
          if (Bodytxt[0].length < 10) {
            result = Bodytxt[0] + Bodytxt[1] + '.' + '\n' + '\n' + 'To learn more, please visit: ' + this.url();
          } else {
            result = Bodytxt[0] + '.' + '\n' + '\n' + 'To learn more, please visit: ' + this.url();
          }
        }
        result = result.replace(/&/g, "and");
        return encodeURIComponent(result);
      }
    }).volatile(),

    pageUrl: computed(function () {
      if (!this.get('isFastBoot')) {
        /*let current = this.get('routing.currentRouteName');
        let description='';
        let title=this.title();
        if (current === "index") {
         description= 'Architectural Adventures is the official travel program of the American Institute of Architects, offering first-rate experiences in breathtaking destinations across the globe.';
          
        } else if (current === "destinations") {
         description=  $.trim($('.page-intro .intro-txt').text());
          
        } else if (current === "experts") {
        description=   'Architectural Adventures selects leading building and design subject matter experts who offer unique insights for each trip.';
          
        } else if (current === "destination") {
          title= $('.banner-title h1').text();
          description= $('.smaller-page-intro h2').text();
        } else if (current === "expert") {
          title= $('.content-wrapper .expert-title').text();
          description= $('.content-wrapper h4').text();
        }*/
        /*$("meta[property='og\\:title']").attr("content", title);
        $("meta[property='og\\:description']").attr("content", description);
        $('meta[name=description]').attr("content", description);*/
        return;
      }
    }).volatile(),

    shareActions: {
      facebook: function facebook(config) {
        if (config.appId == null || !window.FB) {
          openPopup('https://www.facebook.com/sharer/sharer.php', {
            u: this.url()
          });
        } else {
          window.FB.ui({
            method: 'feed',
            name: this.title(),
            link: this.url(),
            picture: this.image(),
            caption: this.caption(),
            description: this.description()
          });
        }
      },
      twitter: function twitter() {
        var current = this.get('routing.currentRouteName');

        if (current === "index") {
          openPopup('https://twitter.com/intent/tweet', {
            text: this.title() + '. ' + 'Architectural Adventures is the official travel program of the American Institute of Architects, offering first-rate experiences in breathtaking destinations across the globe.' + '. ' + this.url()
          });
        } else if (current === "destinations") {
          openPopup('https://twitter.com/intent/tweet', {
            text: this.title() + '. ' + this.description() + '. ' + this.url()
          });
        } else if (current === "experts") {
          openPopup('https://twitter.com/intent/tweet', {
            text: this.title() + '. ' + 'Architectural Adventures selects leading building and design subject matter experts who offer unique insights for each trip.' + '. ' + this.url()
          });
        } else if (current === "destination") {
          openPopup('https://twitter.com/intent/tweet', {
            text: Ember.$('.banner-title h1').text() + '. ' + Ember.$('.smaller-page-intro h2').text() + '. ' + this.url()
          });
        } else if (current === "expert") {
          openPopup('https://twitter.com/intent/tweet', {
            text: Ember.$('.content-wrapper .expert-title').text() + '. ' + Ember.$('.content-wrapper h4').text() + '. ' + this.url()
          });
        }
      },
      googlePlus: function googlePlus() {
        openPopup('https://plus.google.com/share', {
          url: this.url()
        });
      },
      pinterest: function pinterest() {
        var current = this.get('routing.currentRouteName');
        var Bodytxt = Ember.$('.content-wrapper p').text().split('.');
        var Hometxt = Ember.$.trim(Ember.$('.intro-txt').text()).split('.');
        var landTxt = Ember.$.trim(Ember.$('.page-intro .intro-txt').text()).split('.');
        if (current === "index") {
          openPopup('https://www.pinterest.com/pin/create/button', {
            url: this.url(),
            media: document.querySelector('.slide-box img').src,
            description: Hometxt[0]
          });
        } else if (current === "destinations") {
          openPopup('https://www.pinterest.com/pin/create/button', {
            url: this.url(),
            media: document.querySelector('.banner-image img').src,
            description: landTxt[0]
          });
        } else if (current === "experts") {
          openPopup('https://www.pinterest.com/pin/create/button', {
            url: this.url(),
            media: document.querySelector('.banner-image img').src,
            description: landTxt[0]
          });
        } else if (current === "destination") {
          openPopup('https://www.pinterest.com/pin/create/button', {
            url: this.url(),
            media: document.querySelector('.banner-image img').src,
            description: Bodytxt[0]
          });
        } else if (current === "expert") {
          openPopup('https://www.pinterest.com/pin/create/button', {
            url: this.url(),
            media: document.querySelector('.expertimg img').src,
            description: Bodytxt[0]
          });
        }
      },
      linkedin: function linkedin() {
        openPopup('https://www.linkedin.com/shareArticle', {
          mini: 'true',
          url: this.url(),
          title: this.title(),
          summary: this.description()
        });
      },
      reddit: function reddit() {
        openPopup('http://www.reddit.com/submit', {
          url: this.url(),
          title: this.title()
        });
      },
      whatsapp: function whatsapp() {
        openPopup('whatsapp://send', {
          text: this.description + ' ' + this.url()
        });
      }
    },

    actions: {
      share: function share(network) {
        var fn = this.shareActions[network.name];
        if (fn) {
          fn.call(this, network);
        }
      }
    },

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var facebookConfig = this.get('networks').find(function (n) {
        return n.name === 'facebook';
      });
      if (facebookConfig && facebookConfig.appId != null) {
        injectFacebookSdk(facebookConfig.appId);
      }
    },
    url: function url() {
      return window.location.href;
    },
    title: function title() {
      var content = void 0;
      if (content = document.querySelector('meta[property="og:title"]')) {
        return content.getAttribute('content');
      } else if (content = document.querySelector('title')) {
        return content.textContent || content.innerText;
      }
    },
    image: function image() {
      var content = void 0;
      if (content = document.querySelector('meta[property="og:image"]') || document.querySelector('meta[name="twitter:image"]')) {
        return content.getAttribute('content');
      }
    },
    caption: function caption() {},
    description: function description() {
      var content = void 0;
      if (content = document.querySelector('meta[property="og:description"]') || document.querySelector('meta[name="twitter:description"]') || document.querySelector('meta[name="description"]')) {
        return content.getAttribute('content');
      } else {
        return '';
      }
    }
  });


  function expandNetworkConfig(entry) {
    if (typeof entry === 'string') {
      return { name: entry };
    } else {
      return entry;
    }
  }

  function injectFacebookSdk(appId) {
    var bodyTag = Ember.$(document).find('body')[0];

    if (!window.FB && !bodyTag.querySelector('#fb-root')) {
      var script = document.createElement('script');
      script.text = 'window.fbAsyncInit=function(){FB.init({appId:\'' + appId + '\',status:true,xfbml:true})};(function(e,t,n){var r,i=e.getElementsByTagName(t)[0];if (e.getElementById(n)){return}r=e.createElement(t);r.id=n;r.src=\'//connect.facebook.net/en_US/all.js\';i.parentNode.insertBefore(r,i)})(document,\'script\',\'facebook-jssdk\');';

      var fbRoot = document.createElement('div');
      fbRoot.id = 'fb-root';

      bodyTag.appendChild(fbRoot);
      bodyTag.appendChild(script);
    }
  }

  function openPopup(target, params) {

    var qs = Object.keys(params).filter(function (k) {
      return params[k];
    }).map(function (k) {
      return k + '=' + toRFC3986(params[k]);
    }).join('&');

    if (qs) {
      target = target + '?' + qs;
    }

    var popup = {
      width: 500,
      height: 350
    };

    popup.top = screen.height / 2 - popup.height / 2;
    popup.left = screen.width / 2 - popup.width / 2;

    window.open(target, 'targetWindow', '\n          toolbar=no,\n          location=no,\n          status=no,\n          menubar=no,\n          scrollbars=yes,\n          resizable=yes,\n          left=' + popup.left + ',\n          top=' + popup.top + ',\n          width=' + popup.width + ',\n          height=' + popup.height + '\n        ');
  }

  function toRFC3986(s) {
    return encodeURIComponent(s).replace(/[!'()*]/g, function (c) {
      return '%' + c.charCodeAt(0).toString(16);
    });
  }
});