define('aia-travel/routes/trip-support', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return Ember.RSVP.hash({

        destinations: this.store.findAll('destination'),
        trip: this.store.query('generalpage', { reload: true }).then(function (pages) {
          return pages.findBy('pageURLSlug', 'trip-support');
        })

      });
      /*  return this.store.query('generalpage', {reload: true}).then(function(pages) {
              return pages.findBy('pageURLSlug','trip-support');
          }); */
    }

  });
});