define('aia-travel/routes/destination', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var set = Ember.set;
  exports.default = Ember.Route.extend({
    // activate: function() {
    //   window.scrollTo(0,0);
    // },
    headData: Ember.inject.service(),
    model: function model(params) {

      if (params.destinationSlug === 'lisbon-to-london') {
        window.location.replace("http://www.architecturaladventures.org/");
        return false;
      }
      var store = this.store;
      var destination = '';
      var expert = '';

      //Get Destination
      destination = store.query('destination', { reload: false }).then(function (destinations) {
        return destinations.findBy('destinationSlug', params.destinationSlug);
      });

      //Get related Expert, using expertSlug of destination
      /*expertTest = destination.then(function(data){
                                  expertSlug = data.get('expertSlug');
          return expertSlug;
        });*/

      expert = store.query('expert', { reload: false }).then(function (experts) {
        return experts;
      });

      //Return Multiple models for template
      return Ember.RSVP.hash({

        destination: destination,
        expert: expert

      });
    },
    afterModel: function afterModel(model) {
      set(this, 'headData.title', model.destination.get('title'));
      set(this, 'headData.description', model.destination.get('tagline'));
      set(this, 'headData.image', model.destination.get('heroImageURL'));
    }
  }

  //refreshModel: function() {
  //
  //}
  );
});