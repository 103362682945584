define('aia-travel/models/expert', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    title: _emberData.default.attr('string'),
    bioFull: _emberData.default.attr('string'),
    bioShort: _emberData.default.attr('string'),
    profileImageURL: _emberData.default.attr('string'),
    qualification: _emberData.default.attr('string'),
    thumbnailURL: _emberData.default.attr('string'),
    expertSlug: _emberData.default.attr('string'),
    designation: _emberData.default.attr('string'),
    specialtyAreas: _emberData.default.attr('string')
  });
});