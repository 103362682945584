define('aia-travel/components/marketo-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    baseURL: "//app-sj03.marketo.com",

    didInsertElement: function didInsertElement() {

      var removeAttribs = "";
      var hiddenfields = "";
      if (this.get('removeStyles')) {
        removeAttribs = JSON.parse(this.get('removeStyles'));
      }

      if (this.get('hiddenFields')) {
        hiddenfields = this.get('hiddenFields');
      }

      //Get current form div
      var currentForm = document.getElementById('current-marketo-form-' + this.get('formLoc'));
      if (currentForm != null) {
        currentForm.innerHTML = '';
        currentForm.innerHTML = '<form id="mktoForm_' + this.get("formId") + '"></form>';
      }
      var thisParent = this; //Since we need to access 'this' in subfunctions, defining it here, would help.
      var formName = this.get('formLoc');
      //Load Form
      MktoForms2.loadForm(this.get('baseURL'), this.get("munchkinId"), this.get("formId"), function (form) {
        //Hidden Values  //form.vals({ "Country":"Cannada"});
        form.vals(hiddenfields); //TODO: Needs work

        //Remove Attributes
        for (var i = 0; i < removeAttribs.length; i++) {
          Ember.$(removeAttribs[i].className).removeAttr(removeAttribs[i].styleAttribute);
        }

        if (formName === 'destination') {
          form.vals({ "destitation": thisParent.get('selectedCity') });
        }

        Ember.$("#mktoForm_1398").find("input[type='text']").removeAttr("placeholder");
        Ember.$("#mktoForm_1398").find("input[type='email']").removeAttr("placeholder");
        Ember.$("#mktoForm_1397").find("input[type='text']").removeAttr("placeholder");
        //$("#mktoForm_1397").find("input[type='email']").removeAttr("placeholder");
        Ember.$("#mktoForm_1396").find("input[type='text']").removeAttr("placeholder");
        Ember.$("#mktoForm_1396").find("input[type='email']").removeAttr("placeholder");
        Ember.$("#mktoForm_1396").find("input[type='tel']").removeAttr("placeholder");
        Ember.$("#mktoForm_1407").find("input[type='text']").removeAttr("placeholder");
        Ember.$("#mktoForm_1407").find("input[type='email']").removeAttr("placeholder");
        Ember.$("#mktoForm_1658").find("input[type='text']").removeAttr("placeholder");
        //$("#mktoForm_1658").find("input[type='email']").removeAttr("placeholder"); 

        Ember.$("label").each(function () {
          Ember.$(this).html(Ember.$(this).html().replace(":", ""));
        });

        Ember.$("#mktoForm_1398, #mktoForm_1397, #mktoForm_1396, #mktoForm_1407,#mktoForm_1658").find("input").keypress(function () {
          Ember.$('label[for="' + this.id + '"]').css('opacity', '0');
        });

        Ember.$("#mktoForm_1398, #mktoForm_1397, #mktoForm_1396, #mktoForm_1407,#mktoForm_1658").find("input").keyup(function () {
          if (Number(Ember.$(this).val()) !== 0) {
            Ember.$('label[for="' + this.id + '"]').css('opacity', '0');
          } else {
            Ember.$('label[for="' + this.id + '"]').css('opacity', '1');
          }
        });

        //Check if there is a secondary form
        if (thisParent.get('secondaryForm')) {
          form.onSuccess(function () /* values, followUpUrl */{
            //on success of first form
            // Get the form's jQuery element and hide it
            form.getFormElem().hide(); //Hide first form
            sessionStorage.setItem("marketaccesss", true);

            document.getElementById('current-marketo-form-' + thisParent.get('formLoc') + '-headline').innerHTML = thisParent.get('secondFormHeadline');
            document.getElementById('current-marketo-form-' + thisParent.get('formLoc') + '-introduction').innerHTML = thisParent.get('secondFormIntroduction');
            document.getElementById('current-marketo-form-' + thisParent.get('formLoc')).innerHTML = '<form id="mktoForm_' + thisParent.get('secondaryForm') + '"></form>';

            var downloadFileDest = document.getElementById('current-marketo-form-' + thisParent.get('formLoc') + '-hidden').innerHTML;
            if (downloadFileDest.search('null') === -1) {
              var div = document.createElement('div');
              div.className = 'destination-country';
              div.innerHTML = '<h2 class="modal-header thanks-msg"> Thank you! <a class="pdf-link" href = ' + downloadFileDest + ' target = "_blank">Click here to download PDF brochure.</a><br/> To receive our printed catalogs and brochures, please tell us more..</h2><p class="mandatary-field">Fields required (<span class="asterisk">*</span>)</p>';
              var childItems = document.getElementById('current-marketo-form-' + thisParent.get('formLoc'));
              childItems.insertBefore(div, childItems.childNodes[0]);
            } else {
              /*  document.getElementById('current-marketo-form-'+ thisParent.get('formLoc')).innerHTML=""; */
            }

            MktoForms2.loadForm(thisParent.get('baseURL'), thisParent.get("munchkinId"), thisParent.get('secondaryForm'), function (form2) {
              //Load second form
              for (var i = 0; i < removeAttribs.length; i++) {
                Ember.$(removeAttribs[i].className).removeAttr(removeAttribs[i].styleAttribute); //Remove style attributes
              }

              Ember.$("#mktoForm_1408").find("input[type='text']").removeAttr("placeholder");
              Ember.$("#mktoForm_1408").find("input[type='tel']").removeAttr("placeholder");
              Ember.$("#mktoForm_1408").find("textarea").removeAttr("placeholder");
              Ember.$("#mktoForm_1399").find("input[type='text']").removeAttr("placeholder");
              Ember.$("#mktoForm_1399").find("input[type='tel']").removeAttr("placeholder");
              Ember.$("#mktoForm_1399").find("textarea").removeAttr("placeholder");

              Ember.$("label, textarea").each(function () {
                Ember.$(this).html(Ember.$(this).html().replace(":", ""));
              });

              Ember.$("#State").prop("selectedIndex", -1);

              Ember.$("#State").change(function () {
                if (Ember.$(this).children('option:first-child').is(':selected')) {
                  Ember.$(this).children('option:first-child').css("opacity", "0");
                } else {
                  Ember.$('label[for="State"]').css("opacity", "0");
                  Ember.$(this).children('option:first-child').css("opacity", "1");
                }
              });

              Ember.$("#mktoForm_1408, #mktoForm_1399").find("input, textarea").keypress(function () {
                Ember.$('label[for="' + this.id + '"]').css('opacity', '0');
              });

              Ember.$("#mktoForm_1408, #mktoForm_1399").find("input, textarea").keyup(function () {
                if (Number(Ember.$(this).val()) !== 0) {
                  Ember.$('label[for="' + this.id + '"]').css('opacity', '0');
                } else {
                  Ember.$('label[for="' + this.id + '"]').css('opacity', '1');
                }
              });

              form2.onSuccess(function () /* values, followUpUrl */{
                //on success of second form		
                sessionStorage.setItem("marketaccesss", true);
                form.getFormElem().hide(); //Hide second form
                Cookies.set('marketo_' + thisParent.get('formId'), 'Cookie Set', { expires: 365 }); //Set cookie named marketo_formID

                var downloadFile = document.getElementById('current-marketo-form-' + thisParent.get('formLoc') + '-hidden').innerHTML;
                document.getElementById('current-marketo-form-' + thisParent.get('formLoc') + '-headline').innerHTML = thisParent.get('confirmationHeadline');
                document.getElementById('current-marketo-form-' + thisParent.get('formLoc') + '-introduction').innerHTML = thisParent.get('confirmationIntroduction');
                if (downloadFile.search('null') === -1) {
                  document.getElementById('current-marketo-form-' + thisParent.get('formLoc')).innerHTML = 'Click <a class="downloadLink heighlited-text" href = ' + downloadFile + ' target = "_blank">here</a> to download your PDF brochure.';
                } else {
                  document.getElementById('current-marketo-form-' + thisParent.get('formLoc')).innerHTML = '';
                }

                return false;
                /* if(thisParent.get('formLoc') == 'destination'){ //If destination downloadPDF form
                    var downloadPDF = document.getElementById('current-marketo-form-destination-hidden').innerHTML; //Get download PDF link from template div
                      var popup = window.open(downloadPDF, '_blank');
                   if(popup){
                     popup.onload = function(){
                       if(!popup.innerHeight > 0){
                         document.getElementById('current-marketo-form-'+ thisParent.get('formLoc')).innerHTML = 'Looks like you have a popup blocker. Please allow popus from this site to resume download <br> or <a href = ' + downloadPDF + ' target = "_blank"> Click here.</a>';
                         return false;
                       }
                       else{
                         return true;
                       }
                     }
                   }
                   else{
                     document.getElementById('current-marketo-form-'+ thisParent.get('formLoc')).innerHTML = 'Looks like you have a popup blocker. Please allow popus from this site to resume download <br> or <a href = ' + downloadPDF + ' target = "_blank"> Click Here </a>';
                     return false;
                   }
                }
                if(thisParent.get('formLoc') == 'catalog'){ //If catalog form
                var downloadCatalog = "http://kbsdrupal8testlk6itumszo.devcloud.acquia-sites.com/sites/default/files/2016-04/Renew_Screenshots.pdf" //TODO: Get download PDF link from template div
                     var popup = window.open(downloadCatalog, '_blank');
                  if(popup){
                    popup.onload = function(){
                      if(!popup.innerHeight > 0){
                        document.getElementById('current-marketo-form-'+ thisParent.get('formLoc')).innerHTML = 'Looks like you have a popup blocker. Please allow popus from this site to resume download <br> or <a href = ' + downloadCatalog + ' target = "_blank"> Click Here </a>';
                        return false;
                      }
                      else{
                        return true;
                      }
                    }
                  }
                  else{
                    document.getElementById('current-marketo-form-'+ thisParent.get('formLoc')).innerHTML = 'Looks like you have a popup blocker. Please allow popus from this site to resume download <br> or <a href = ' + downloadCatalog + ' target = "_blank"> Click Here </a>';
                    return false;
                  }
                } */
              });
            });
            // Return false to prevent the submission handler from taking the lead to the follow up url
            return false;
          });
        } else {
          form.onSuccess(function () /* values, followUpUrl */{
            sessionStorage.setItem("marketaccesss", true);
            Cookies.set('marketo_' + thisParent.get('formId'), 'Cookie Set', { expires: 365 });
            //Thank you for contact'n' us
            if (thisParent.get('formLoc') === 'contact') {
              Ember.$("#form-contact-acknowledgement-modal-container").show();
              Ember.$('.modal-overlay').fadeIn(500);
              //  setTimeout(function(){
              //    location.reload();
              //  }, 3000);
              return false;
            }
            if (thisParent.get('formLoc') === 'subscribe') {
              //Thank you for registering
              Ember.$("#form-acknowledgement-modal-container").show();
              Ember.$('.modal-overlay').fadeIn(500);
              // setTimeout(function(){
              //   location.reload();
              // }, 3000);
              return false;
            }
            if (thisParent.get('formLoc') === 'subscribe-popup') {
              sessionStorage.setItem("marketaccesss", true);
              Ember.$("#subscribe-modal-container").hide();
              Ember.$('.subsscribe-modal-overlay').fadeOut(500);
              Ember.$(".close-btn").hide();
              Ember.$("#form-acknowledgement-modal-container").show();
              Ember.$('.modal-overlay').fadeIn(500);
              setTimeout(function () {
                Ember.$("#form-acknowledgement-modal-container").hide();
                Ember.$('.modal-overlay').fadeOut(500);
              }, 3000);
              return false;
            }
          });
        }
      });
    }
  });
});