define('aia-travel/controllers/index', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        megamenu: Ember.inject.service(),
        // ------------------------
        actions: {
            DownloadTravelCatalog: function DownloadTravelCatalog() {
                Ember.$("#catalog-modal-container").show();
                this.set('megamenu.displayModal', true);
                Ember.$('.modal-overlay').fadeIn(500);
            },
            modalClose: function modalClose() {
                Ember.$("#catalog-modal-container").hide();
                this.set('megamenu.displayModal', false);
                Ember.$('.modal-overlay').fadeOut(500);
                location.reload();
                //self.removeClass("hover");
            }
        }

    });
});