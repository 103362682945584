define('aia-travel/helpers/absolute-url', ['exports', 'aia-travel/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.absoluteUrl = absoluteUrl;
  function absoluteUrl(relativeurl) {
    var absoluteURL = '' + _environment.default.AIA_DRUPAL_URL + relativeurl;
    return absoluteURL;
  }

  exports.default = Ember.Helper.helper(absoluteUrl);
});