define('aia-travel/routes/spring2019catalog', ['exports', 'aia-travel/routes/foreign-redirect'], function (exports, _foreignRedirect) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var redirects = {
    'asid': 'http://acdpages.aia.org/Travel-Welcome-Catalog-Download.html?pid=A10211&utm_source=ASID&utm_campaign=&utm_medium=e-blast&utm_content=&utm_term=04-11-2019&utm_adsize=&utm_tad=',
    'aafb': 'http://acdpages.aia.org/Travel-Welcome-Catalog-Download.html?pid=A10212&utm_source=Facebook&utm_campaign=&utm_medium=social&utm_content=&utm_term=04-11-2019&utm_adsize=&utm_tad=',
    'aatwitter': 'http://acdpages.aia.org/Travel-Welcome-Catalog-Download.html?pid=A10213&utm_source=Twitter&utm_campaign=&utm_medium=social&utm_content=&utm_term=04-11-2019&utm_adsize=&utm_tad=',
    'aainstagram': 'http://acdpages.aia.org/Travel-Welcome-Catalog-Download.html?pid=A10214&utm_source=Instagram&utm_campaign=&utm_medium=social&utm_content=&utm_term=04-11-2019&utm_adsize=&utm_tad=',
    'aia': 'http://acdpages.aia.org/Travel-Welcome-Catalog-Download.html?pid=A10215&utm_source=Members&utm_campaign=&utm_medium=email&utm_content=&utm_term=04-11-2019&utm_adsize=&utm_tad='
  };

  exports.default = _foreignRedirect.default.extend({
    beforeModel: function beforeModel(transition) {
      var catalog = transition.params['spring2019catalog'].catalog;
      var redirect = redirects[catalog];
      if (redirect) {
        this.set('destination', redirect);
        this._super();
      } else {
        this.transitionTo('fourohfour', 'spring2019catalog/' + catalog);
      }
    }
  });
});