define('aia-travel/controllers/destinations', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    megamenu: Ember.inject.service(),
    init: function init() {
      this.set('refresh', true);
    },
    filterDestinations: Ember.computed('model.destinations', 'megamenu.selectedRegion', 'megamenu.selectedDuration', 'megamenu.selectedDate', 'megamenu.selectedYear', function () {
      var selectedRegion = this.get('megamenu.selectedRegion'),
          selectedDuration = this.get('megamenu.selectedDuration'),
          selectedYear = this.get('megamenu.selectedYear'),
          selectedDate = this.get('megamenu.selectedDate'),
          yearFilterCount = null;

      var destinations = this.copyObject(this.get('model.destinations'));

      destinations.forEach(function (item) {
        var startDate = item.startDate,
            endDate = item.endDate;
        startDate = new Date(startDate.split(" ")[1]);
        endDate = new Date(endDate.split(" ")[1]);
        var timeDiff = Math.abs(endDate.getTime() - startDate.getTime());
        var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)) + 1;
        Ember.set(item, 'duration', diffDays);
      });
      destinations.sort(function (oldDate, newDate) {
        oldDate = oldDate.startDate;
        newDate = newDate.startDate;
        oldDate = new Date(oldDate.split(" ")[1]);
        newDate = new Date(newDate.split(" ")[1]);
        return oldDate - newDate;
      });
      if (selectedYear.length && !selectedDate.length) {
        yearFilterCount = destinations.filter(function (val) {
          var startDate = val.startDate,
              endDate = val.endDate;
          startDate = new Date(startDate.split(" ")[1]);
          endDate = new Date(endDate.split(" ")[1]);
          return selectedYear.indexOf((0, _moment.default)(startDate).year().toString()) > -1 && (0, _moment.default)(startDate).isSameOrAfter((0, _moment.default)(), 'month');
        });
        destinations = yearFilterCount;
        yearFilterCount = yearFilterCount.length;
      }
      if (selectedDuration.value) {
        destinations = destinations.filter(function (val) {
          return val.duration >= selectedDuration.min && (selectedDuration.max ? val.duration <= selectedDuration.max : true);
        });
      }
      if (selectedRegion.value) {
        destinations = destinations.filter(function (val) {
          return val.region === selectedRegion.value;
        });
      }
      if (selectedDate.length) {
        destinations = destinations.filter(function (val) {
          var startDate = val.startDate,
              endDate = val.endDate;
          startDate = new Date(startDate.split(" ")[1]);
          endDate = new Date(endDate.split(" ")[1]);
          if (selectedYear.length > 1) {
            return (0, _moment.default)(startDate).month() === (0, _moment.default)(selectedDate[0]).month();
          } else {
            return (0, _moment.default)(startDate).isSame((0, _moment.default)(selectedDate[0]), 'month');
          }
        });
      }
      this.set('filteredDestinations', destinations.length);

      var returnObj = {
        destinations: destinations,
        filterDestinationsCount: this.get('filteredDestinations'),
        selectedYear: selectedYear,
        isFilterSelected: selectedDuration.value || selectedRegion.value || selectedDate.length,
        yearFilterCount: yearFilterCount
      };
      return returnObj;
    }),
    mobileFilterDestinations: Ember.computed('model.destinations', function () {
      var destinations = this.copyObject(this.get('model.destinations'));
      var yearFilter = destinations.filter(function (val) {
        var startDate = val.startDate;
        startDate = new Date(startDate.split(" ")[1]);
        return (0, _moment.default)(startDate).isSameOrAfter((0, _moment.default)(), 'month');
      });
      destinations = yearFilter;

      destinations.sort(function (oldDate, newDate) {
        oldDate = oldDate.startDate;
        newDate = newDate.startDate;
        oldDate = new Date(oldDate.split(" ")[1]);
        newDate = new Date(newDate.split(" ")[1]);
        return oldDate - newDate;
      });
      this.set('mobilefilteredDestinations', destinations.length);

      var returnObj = {
        destinations: destinations
      };
      return returnObj;
    }),
    copyObject: function copyObject(model) {
      var returnArray = [];
      model.forEach(function (item) {
        returnArray.push(JSON.parse(JSON.stringify(item)));
      });
      return returnArray;
    },
    actions: {
      DownloadTravelCatalog: function DownloadTravelCatalog() {
        Ember.$("#catalog-modal-container").show();
        this.set('megamenu.displayModal', true);
        Ember.$('.modal-overlay').fadeIn(500);
      },
      modalClose: function modalClose() {
        Ember.$("#catalog-modal-container").hide();
        this.set('megamenu.displayModal', false);
        Ember.$('.modal-overlay').fadeOut(500);
        location.reload();
        //self.removeClass("hover");
      },
      clearFilter: function clearFilter() {
        var _this = this;

        this.set('megamenu.refresh', false);
        this.set('megamenu.selectedRegion', {});
        this.set('megamenu.selectedDuration', {});
        this.set('megamenu.selectedDate', []);
        this.set('megamenu.selectedYear', []);
        Ember.run.next(function () {
          _this.set('megamenu.refresh', true);
        });
      }
    }
  });
});