define("aia-travel/controllers/expert", ["exports"], function (exports) {
                      "use strict";

                      Object.defineProperty(exports, "__esModule", {
                                            value: true
                      });
                      exports.default = Ember.Controller.extend({
                                            destinations: function () {
                                                                  var thisModel, destinations;
                                                                  thisModel = this.get("model");
                                                                  destinations = thisModel.destination.filterBy("expertSlug", thisModel.expert.get("expertSlug"));
                                                                  return destinations;
                                            }.property('destinations')

                      });
});