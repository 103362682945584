define("aia-travel/helpers/get-grid-class", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getGridClass = getGridClass;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function getGridClass(_ref) {
    var _ref2 = _slicedToArray(_ref, 1),
        gridVal = _ref2[0];

    var returnString;
    if (gridVal === 1) {
      returnString = "grid-item-height2";
    } else if (gridVal === 2) {
      returnString = "grid-item-width2";
    } else {
      returnString = "";
    }
    return returnString;
  }

  exports.default = Ember.Helper.helper(getGridClass);
});