define('aia-travel/app', ['exports', 'aia-travel/resolver', 'ember-load-initializers', 'aia-travel/config/environment'], function (exports, _resolver, _emberLoadInitializers, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var App = void 0;
  var FAKE_WINDOW = {
    scrollTo: function scrollTo() {}
  };
  Ember.MODEL_FACTORY_INJECTIONS = true;

  App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default
  });
  // start at the top of page
  var w = void 0;
  w = typeof FastBoot === 'undefined' ? window : FAKE_WINDOW, Ember.Route.reopen({

    fastboot: Ember.inject.service(),
    isFastBoot: Ember.computed.reads('fastboot.isFastBoot'),
    //actions : {


    // didTransition() {
    // debugger;
    // if(!this.isFastboot){
    //   Ember.run.schedule("afterRender", function(){
    //             window.scrollTo(0, 0);

    //     });s
    //   }


    //      }
    //   }

    render: function render() {
      this._super();
      if (!this.isFastboot) {
        Ember.run.schedule("afterRender", function () {
          w.scrollTo(0, 0);
        });
      }
    }

    //   render: function() {
    //     this._super();
    //     //Emer.run.schedule("afterRender", function(){b
    //      window.scrollTo(0, 0);
    //     $("#destination-modal-conatiner").hide();
    //     $(".modal-overlay").fadeOut(500);
    //     //});


    //   }
    // didInsertElement(){
    //   window.scrollTo(0, 0);
    //   $("#destination-modal-conatiner").hide();
    //   $(".modal-overlay").fadeOut(500);

    // }
    //  beforeModel () {
    // window.scrollTo(0, 0);
    //    $("#destination-modal-conatiner").hide();
    //    $(".modal-overlay").fadeOut(500);


    //  }

    // activate : function (){
    //  window.scrollTo(0, 0);
    //     $("#destination-modal-conatiner").hide();
    //     $(".modal-overlay").fadeOut(500);

    // }
    // didTransistion 

    //   didInsertElement : function (){

    //         this._super();
    //     //Ember.run.schedule("afterRender", function(){
    // window.scrollTo(0, 0);
    //     $("#destination-modal-conatiner").hide();
    //     $(".modal-overlay").fadeOut(500);
    //   }

    // afterRender : function (){
    //   this._super();
    //   window.scrollTo(0, 0);
    //   $("#destination-modal-conatiner").hide();
    //   $(".modal-overlay").fadeOut(500);
    // }
  });

  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);

  exports.default = App;
});