define('aia-travel/controllers/contact', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      modalClose: function modalClose() {
        Ember.$("#form-contact-acknowledgement-modal-container").hide();
        Ember.$('.modal-overlay').fadeOut(500);
        location.reload();
        //self.removeClass("hover");
      }
    }
  });
});