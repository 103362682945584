define('aia-travel/helpers/truncate-text', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.truncateText = truncateText;
  function truncateText(params, hash) {
    var value = params[0];
    var len = hash.limit;
    var titleLength = hash.titleLength,
        taglineLength = hash.taglineLength;
    var out = '';
    var regexp = /<p/g;
    var match,
        matches = [];

    if (value !== undefined) {
      while ((match = regexp.exec(value)) != null) {
        matches.push(match.index);
      }
      len = matches.length > 3 ? matches.length > 5 ? len - 230 : len - 50 : len;
      out = value.substr(0, len);
      matches = [];
      while ((match = regexp.exec(out)) != null) {
        matches.push(match.index);
      }
      out = matches.length > 1 ? matches.length > 2 ? out.substr(0, len - 10) : out.substr(0, len - 100) : out;
      if (titleLength > 33) out = titleLength > 34 ? out.substr(0, len - titleLength * 4) : out;
      if (taglineLength > 46) out = taglineLength > 46 ? out.substr(0, len - taglineLength * 2.5) : out;

      if (value.length > out.length) {
        out += '... <a href="#" class="read-more-btn">Learn more ></a>';
      } else {
        out += ' <a href="#" class="read-more-btn">Learn more ></a>';
      }
    } else {
      out = '';
    }

    return out;
  }

  exports.default = Ember.Helper.helper(truncateText);
});