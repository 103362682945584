define('aia-travel/helpers/is-cookie-set', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    fastboot: Ember.inject.service(),

    _getCookie: function _getCookie(name) {
      if (this.get('fastboot.isFastBoot')) {
        return this.get('fastboot.cookies.marketo_' + name);
      } else {
        return Cookies.get('marketo_' + name);
      }
    },
    compute: function compute(params) {
      return this._getCookie(params) === 'Cookie Set';
    }
  });
});