define('aia-travel/routes/destinations', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var set = Ember.set;
  exports.default = Ember.Route.extend({
    // activate: function() {
    //   window.scrollTo(0,0);
    // },
    headData: Ember.inject.service(),
    model: function model() {
      return Ember.RSVP.hash({
        //      carousel : this.store.findAll('carousel'),
        destinations: this.store.findAll('destination')
      });

      //return this.store.findAll('destination');
      // destinations : this.store.findAll('destination'),
    },
    afterModel: function afterModel() {
      set(this, 'headData.title', 'Architectural Adventures');
      set(this, 'headData.description', "Architectural Adventures carefully selects destinations that stand out for the global significance of their architecture and culture. Whether you're an architectural enthusiast or an intellectually curious traveler, our trips are designed to expand your understanding and appreciation of the built environment and its place in the wider world.");
      set(this, 'headData.image', 'images/general-banner.jpg');
    }
  });
});