define('aia-travel/routes/json-demo', ['exports', 'aia-travel/config/environment'], function (exports, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		model: function model() {
			//	var url = 'http://kbsdrupal8testlk6itumszo.devcloud.acquia-sites.com/testcontentjson/';
			var url = _environment.default.AIA_DRUPAL_URL + '/testcontentjson/';
			return Ember.$.getJSON(url);
		}
	});
});