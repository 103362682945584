define('aia-travel/components/aia-footer', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      modalClose: function modalClose() {
        Ember.$("#form-acknowledgement-modal-container").hide();
        Ember.$('.modal-overlay').fadeOut(500);
        location.reload();
      },
      scrollToTop: function scrollToTop() {
        Ember.$("html, body").animate({ "scrollTop": 0 }, 1000);
      }
    }
  });
});