define('aia-travel/models/destination', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    title: _emberData.default.attr('string'),
    availableSpots: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    downloadPDF: _emberData.default.attr('string'),
    endDate: _emberData.default.attr('string'),
    startDate: _emberData.default.attr('string'),
    expertSlug: _emberData.default.attr('string'),
    destinationSlug: _emberData.default.attr('string'),
    heroImageURL: _emberData.default.attr('string'),
    thumbnailURL: _emberData.default.attr('string'),
    price: _emberData.default.attr('price'),
    additionalDescription: _emberData.default.attr('string'),
    itineraryImage: _emberData.default.attr('string'),
    itineraryTitle: _emberData.default.attr('string'),
    destinationIcon: _emberData.default.attr('string'),
    itineraryData: _emberData.default.attr(),
    bannerClass: _emberData.default.attr('string', { defaultValue: "page-destination" }),
    itineraryTab: _emberData.default.attr('string', { defaultValue: "overview" }),
    expertQuote: _emberData.default.attr('string'),
    gridStyle: _emberData.default.attr('number', { defaultValue: 0 }),
    tagline: _emberData.default.attr('string'),
    region: _emberData.default.attr('string')
  });
});