define('aia-travel/controllers/destinations-filter', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        actions: {
            DownloadTravelCatalog: function DownloadTravelCatalog() {
                Ember.$("#catalog-modal-container").show();
                Ember.$('.modal-overlay').fadeIn(500);
            },
            modalClose: function modalClose() {
                Ember.$("#catalog-modal-container").hide();
                Ember.$('.modal-overlay').fadeOut(500);
                location.reload();
                //self.removeClass("hover");
            }
        }
    });
});