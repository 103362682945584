define('aia-travel/services/megamenu', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    selectedRegion: {},
    selectedDuration: {},
    selectedYear: [],
    selectedDate: [],
    refresh: true,
    displayModal: false
  });
});