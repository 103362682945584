define('aia-travel/components/destination-map', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  /* global google */
  var FAKE_GOOGLE = {
    maps: {
      OverlayView: function OverlayView() {},
      overlayImage: function overlayImage() {},
      LatLng: function LatLng() {},
      MAP: function MAP() {},
      marker: function marker() {},

      event: {
        addListener: function addListener() {},
        addDomListener: function addDomListener() {},
        trigger: function trigger() {}
      },
      MapTypeId: {
        ROADMAP: null
      },
      ControlPosition: {
        LEFT_TOP: null
      }

    }

  };

  var FAKE_NAVIGATOR = {
    userAgent: {
      toLowerCase: function toLowerCase() {
        function indexOf() {}
      }
    }
  };

  var FAKE_DOCUMENT = {
    createElement: function createElement() {}
  };

  var browGoogle = void 0;
  var browNavigator = void 0;
  var browDocument = void 0;
  browGoogle = typeof FastBoot === 'undefined' ? google : FAKE_GOOGLE;
  browNavigator = typeof FastBoot === 'undefined' ? navigator : FAKE_NAVIGATOR;
  browDocument = typeof FastBoot === 'undefined' ? document : FAKE_DOCUMENT;

  exports.default = Ember.Component.extend({
    fastboot: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      var store = this.get('model');
      var markers = [];
      if (store != null) {
        store.map(function (item) {
          markers.push(item.geoLocation.replace(" ", "").split(","));
        });
        if (!this.get('fastboot.isFastBoot')) {
          setmarkerPosition(markers, true);
        }
      }
    },

    onTabValueChanged: function () {
      var tabValue = this.get('tabValue');
      var store = this.get('model');
      var markers = [];
      if (store != null) {
        if (tabValue === "overview") {
          store.map(function (item) {
            markers.push(item.geoLocation.replace(" ", "").split(","));
          });
          if (!this.get('fastboot.isFastBoot')) {
            setmarkerPosition(markers, true);
          }
        } else {
          var location = store.findBy("day", tabValue).geoLocation;
          location = location.replace(" ", "").split(",");
          markers.push(location);
          if (!this.get('fastboot.isFastBoot')) {
            setmarkerPosition(markers, false);
          }
        }
      }
    }.observes('tabValue')
  });


  /* Custom Label */
  function Label(opt_options) {
    this.setValues(opt_options);
    var span = this.span_ = browDocument.createElement('span');
    span.className = "googleCustomLabel";
    var div = this.div_ = browDocument.createElement('div');
    div.appendChild(span);
    div.style.cssText = 'position: absolute; display: none';
  }
  Label.prototype = new browGoogle.maps.OverlayView();
  Label.prototype.onAdd = function () {
    var pane = this.getPanes().overlayImage;
    pane.appendChild(this.div_);
    var me = this;
    this.listeners_ = [browGoogle.maps.event.addListener(this, 'position_changed', function () {
      me.draw();
    }), browGoogle.maps.event.addListener(this, 'visible_changed', function () {
      me.draw();
    }), browGoogle.maps.event.addListener(this, 'clickable_changed', function () {
      me.draw();
    }), browGoogle.maps.event.addListener(this, 'text_changed', function () {
      me.draw();
    }), browGoogle.maps.event.addListener(this, 'zindex_changed', function () {
      me.draw();
    }), browGoogle.maps.event.addDomListener(this.div_, 'click', function () {
      if (me.get('clickable')) {
        browGoogle.maps.event.trigger(me, 'click');
      }
    })];
  };
  Label.prototype.draw = function () {
    var projection = this.getProjection();
    var position = projection.fromLatLngToDivPixel(this.get('position'));

    var div = this.div_;
    div.style.left = position.x + 'px';
    div.style.top = position.y + 'px';
    div.style.display = 'block';

    this.span_.innerHTML = this.get('text').toString();
  };
  /* Custom Label */

  var is_internetExplorer11 = browNavigator.userAgent.toLowerCase() ? browNavigator.userAgent.toLowerCase().indexOf('trident') > -1 : null;
  var marker_url = is_internetExplorer11 ? 'images/destinations/dest-icon-location.png' : 'images/destinations/dest-icon-location.svg';
  var marker_url_label = is_internetExplorer11 ? 'images/destinations/dest-icon-location.png' : 'images/destinations/dest-icon-location-label.svg';

  var style = [{
    "featureType": "water",
    "elementType": "geometry.fill",
    "stylers": [{
      "color": "#E4E4E4"
    }]
  }, {
    "featureType": "transit",
    "stylers": [{
      "color": "#808080"
    }, {
      "visibility": "off"
    }]
  }, {
    "featureType": "road.highway",
    "elementType": "geometry.stroke",
    "stylers": [{
      "visibility": "on"
    }, {
      "color": "#b3b3b3"
    }]
  }, {
    "featureType": "road.highway",
    "elementType": "geometry.fill",
    "stylers": [{
      "color": "#ffffff"
    }]
  }, {
    "featureType": "road.local",
    "elementType": "geometry.fill",
    "stylers": [{
      "visibility": "on"
    }, {
      "color": "#ffffff"
    }, {
      "weight": 1.8
    }]
  }, {
    "featureType": "road.local",
    "elementType": "geometry.stroke",
    "stylers": [{
      "color": "#d7d7d7"
    }]
  }, {
    "featureType": "poi",
    "elementType": "geometry.fill",
    "stylers": [{
      "visibility": "on"
    }, {
      "color": "#ebebeb"
    }]
  }, {
    "featureType": "administrative",
    "elementType": "geometry",
    "stylers": [{
      "color": "#B0B0B0"
    }]
  }, {
    "featureType": "road.arterial",
    "elementType": "geometry.fill",
    "stylers": [{
      "color": "#ffffff"
    }]
  }, {
    "featureType": "road.arterial",
    "elementType": "geometry.fill",
    "stylers": [{
      "color": "#ffffff"
    }]
  }, {
    "featureType": "landscape",
    "elementType": "geometry.fill",
    "stylers": [{
      "visibility": "on"
    }, {
      "color": "#efefef"
    }]
  }, {
    "featureType": "road",
    "elementType": "labels.text.fill",
    "stylers": [{
      "color": "#696969"
    }]
  }, {
    "featureType": "administrative",
    "elementType": "labels.text.fill",
    "stylers": [{
      "visibility": "on"
    }, {
      "color": "#737373"
    }]
  }, {
    "featureType": "poi",
    "elementType": "labels.icon",
    "stylers": [{
      "visibility": "off"
    }]
  }, {
    "featureType": "poi",
    "elementType": "labels",
    "stylers": [{
      "visibility": "off"
    }]
  }, {
    "featureType": "road.arterial",
    "elementType": "geometry.stroke",
    "stylers": [{
      "color": "#d6d6d6"
    }]
  }, {
    "featureType": "road",
    "elementType": "labels.icon",
    "stylers": [{
      "visibility": "off"
    }]
  }, {}, {
    "featureType": "poi",
    "elementType": "geometry.fill",
    "stylers": [{
      "color": "#E4E4E4"
    }]
  }];

  var map_zoom = 10;
  var map_options = {
    zoom: map_zoom,
    panControl: false,
    zoomControl: false,
    mapTypeControl: false,
    streetViewControl: false,
    mapTypeId: browGoogle.maps.MapTypeId.ROADMAP,
    scrollwheel: false,
    styles: style
  };

  function setmarkerPosition(markers, showLabel) {
    if (markers.length === 1) {
      map_options.center = new browGoogle.maps.LatLng(markers[0][0], markers[0][1]);
    }
    var bounds = new browGoogle.maps.LatLngBounds();
    var map = new browGoogle.maps.Map(browDocument.getElementById('google-container'), map_options);
    var marker;
    if (markers.length > 1) {
      for (var i = 0; i < markers.length; i++) {
        var position = new browGoogle.maps.LatLng(markers[i][0], markers[i][1]);
        bounds.extend(position);
        var options = {
          position: position,
          map: map,
          icon: marker_url
        };
        if (showLabel) {
          options.icon = marker_url_label;
        }
        marker = new browGoogle.maps.Marker(options);
        if (showLabel) {
          var label = new Label({
            map: map
          });
          label.bindTo('position', marker);
          label.set('text', i + 1);
          label.bindTo('visible', marker);
          label.bindTo('clickable', marker);
          browGoogle.maps.event.addListener(label, 'click', clickHandler);
        }
        map.fitBounds(bounds);
      }
    } else {
      marker = new browGoogle.maps.Marker({
        position: new browGoogle.maps.LatLng(markers[0][0], markers[0][1]),
        map: map,
        visible: true,
        icon: marker_url
      });
    }
    var zoomControlDiv = browDocument.createElement('div');
    map.controls[browGoogle.maps.ControlPosition.LEFT_TOP].push(zoomControlDiv);
  }

  function clickHandler() {
    var itineraryID = this.text;
    Ember.$('.itinerary-schedule-wrapper a[href="#destination-day' + itineraryID + '"]').trigger("click");
  }
});