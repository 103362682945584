define('aia-travel/transforms/price', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Transform.extend({
    serialize: function serialize(value) {
      return value;
    },
    deserialize: function deserialize(value) {
      if (value != null) {
        return value % 1 === 0 ? parseInt(value).toLocaleString('en-US', { style: 'decimal', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 2 }) : value;
      }
    }
  });
});