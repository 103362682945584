define('aia-travel/components/generate-leads', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		fastboot: Ember.inject.service(),

		showSubscribeMarketoForm: false,
		handleResize: function handleResize(e) {

			var marketAccessFirstTimeAccess = sessionStorage.getItem("marketaccesss");
			if (marketAccessFirstTimeAccess) {
				return false;
			}
			e = e ? e : window.event;
			var from = e.relatedTarget || e.toElement;
			if ((!from || from.nodeName === "HTML") && e.pageY < Ember.$(window).scrollTop()) {
				Ember.$('.footer-company-about-text').remove();
				Ember.$('.subscribe-form').remove();
				this.set('showSubscribeMarketoForm', true);
				Ember.$("#subscribe-modal-container").show();
				Ember.$('.subsscribe-modal-overlay').fadeIn(500);
				Ember.$(window).unbind('mouseout');
				//}
			}
		},
		// didInsertElement(){
		// 	//$(window).on('scroll.aia-header', () => this.updateStickiness());

		// },
		willDestroyElement: function willDestroyElement() {
			//$(window).off('scroll.aia-header');
		},
		init: function init() {

			this._super.apply(this, arguments);
			//$(window).off('scroll.aia-header');
			//console.log(this.get("fastboot.isFastboot"));
			//console.log(this.get("fastboot"));
			/*if(typeof FastBoot !== 'undefined'){
   	
    var  marketAccessFirstTimeAccess=sessionStorage.getItem("marketaccesss");	
    if(!marketAccessFirstTimeAccess){	
    $(window).bind('mouseout', Ember.run.bind(this, this.handleResize));  
    }
   }*/
		},
		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);

			var marketAccessFirstTimeAccess = sessionStorage.getItem("marketaccesss");
			if (!marketAccessFirstTimeAccess) {
				Ember.$(window).bind('mouseout', Ember.run.bind(this, this.handleResize));
			}
		},

		actions: {
			subscribeModalClose: function subscribeModalClose() {
				Ember.$("#subscribe-modal-container").hide();
				Ember.$('.subsscribe-modal-overlay').fadeOut(500);
				this.set('showSubscribeMarketoForm', false);
				//location.reload();        	   
			}
		}
	});
});