define("aia-travel/components/aia-expert-block", ["exports"], function (exports) {
   "use strict";

   Object.defineProperty(exports, "__esModule", {
      value: true
   });
   exports.default = Ember.Component.extend({
      expert: Ember.computed(function () {
         var expertsArray, expert, currentExpert;
         expertsArray = this.get("expertsArray");
         currentExpert = this.get("currentExpert");
         expert = expertsArray.findBy("expertSlug", currentExpert);
         return expert;
      })
   });
});