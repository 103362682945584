define('aia-travel/helpers/date-period', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.datePeriod = datePeriod;
  function datePeriod(params) {
    if (params[0]) {
      var startDate = void 0,
          endDate = void 0,
          returnString = "";
      startDate = params[0];
      endDate = params[1];
      startDate = (0, _moment.default)(startDate.split(" ")[1]);
      endDate = (0, _moment.default)(endDate.split(" ")[1]);
      if (startDate.year() === endDate.year()) {
        if (startDate.month() === endDate.month()) {
          returnString = startDate.format('MMMM') + ' ' + startDate.format('D') + ' - ' + endDate.format('D') + ',  ' + endDate.format('YYYY');
        } else {
          returnString = startDate.format('MMMM') + ' ' + startDate.format('D') + ' - ' + endDate.format('MMMM') + ' ' + endDate.format('D') + ', ' + endDate.format('YYYY');
        }
      } else {
        returnString = startDate.format('MMMM') + ' ' + startDate.format('D') + ', ' + startDate.format('YYYY') + ' - ' + endDate.format('MMMM') + ' ' + endDate.format('D') + ', ' + endDate.format('YYYY');
      }
      return returnString;
    }
  }
  exports.default = Ember.Helper.helper(datePeriod);
});