define('aia-travel/components/action-buttons', ['exports', 'aia-travel/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Component.extend({
    shareConfig: computed(function () {
      return _environment.default.share;
    })
  });
});