define('aia-travel/controllers/experts', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      viewDetails: function viewDetails(id) {
        Ember.$("#flip-container-" + id).addClass("hover");
      },

      flipBack: function flipBack(id) {
        Ember.$("#flip-container-" + id).removeClass("hover");
      }
    },
    showDestination: Ember.computed('model.experts', 'model.destinations', function () {
      var _this = this;

      var experts = this.get('model.experts');
      if (experts) {
        this.get('model.experts').forEach(function (expert) {
          var hasDestination = _this.get('model.destinations').filter(function (destination) {
            return destination.get('expertSlug') === expert.get('expertSlug');
          });
          expert.set('hasDestination', hasDestination.length ? true : false);
        });
      }
      return;
    })
  });
});