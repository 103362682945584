define("aia-travel/routes/demo-links", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		// activate: function() {
		//   window.scrollTo(0,0);
		// },
		model: function model() {
			return [{
				name: "Home Page",
				route: "index"
			}, {
				name: "Destinations",
				route: "destinations"
			}, {
				name: "Experts",
				route: "experts"
			}];
		}
	});
});