define('aia-travel/routes/generalpage', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    activate: function activate() {
      //window.scrollTo(0,0);
      //  $(document).scrollTop()
    },

    model: function model(params) {
      return this.store.query('generalpage', { reload: true }).then(function (pages) {
        return pages.findBy('pageURLSlug', params.pageURLSlug);
      });
    }
  });
});