define('aia-travel/helpers/ember-math', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function (params) {
    var output, firstValue, secondValue;
    output = false;
    firstValue = parseInt(params[1]);
    secondValue = parseInt(params[2]);
    switch (params[0]) {
      case 'gt':
        {
          output = firstValue > secondValue ? true : false;
          break;
        }
      default:
        {
          output = false;
        }
    }
    return output;
  });
});