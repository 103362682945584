define('aia-travel/routes/experts', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var set = Ember.set;
  exports.default = Ember.Route.extend({
    // activate: function() {
    //   window.scrollTo(0,0);
    // },
    headData: Ember.inject.service(),
    model: function model() {
      return Ember.RSVP.hash({
        destinations: this.store.findAll('destination'),
        experts: this.store.findAll('expert')
      });
    },
    afterModel: function afterModel() {
      set(this, 'headData.title', 'Architectural Adventures');
      set(this, 'headData.description', "Architectural Adventures selects leading building and design subject matter experts who offer unique insights for each trip.");
      set(this, 'headData.image', 'images/experts/about-banner-trans.jpg');
    }
  });
});