define('aia-travel/routes/expert', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var set = Ember.set;
  exports.default = Ember.Route.extend({
    // activate: function() {
    //   window.scrollTo(0,0);
    // },
    headData: Ember.inject.service(),
    model: function model(params) {
      var store = this.store;
      var destination = '';
      var expert = '';

      //Get Expert
      expert = store.query('expert', { reload: true }).then(function (experts) {
        return experts.findBy('expertSlug', params.expertSlug);
      });

      //Get related Destinations, using expertSlug of expert
      destination = store.query('destination', { reload: true }).then(function (destinations) {
        return destinations;
      });

      //Return Multiple models for template
      return Ember.RSVP.hash({

        destination: destination,
        expert: expert

      });
    },
    afterModel: function afterModel(model) {
      var expertDetail = model.expert.get('bioShort') ? '. ' + model.expert.get('bioShort').replace(/<(?:.|\n)*?>/gm, '') : '';
      set(this, 'headData.title', model.expert.get('title'));
      set(this, 'headData.description', '' + model.expert.get('designation') + expertDetail);
      set(this, 'headData.image', model.expert.get('profileImageURL'));
    }
  });
});