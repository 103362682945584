define('aia-travel/components/mega-menu/region', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    init: function init() {
      this._super();
      this.set('config', this.makeRegionList());
      this.checkRegion();
    },

    makeRegionList: function makeRegionList() {
      var data = ['Africa', 'Asia', 'Europe', 'North America', 'Oceania', 'South America'];
      var regionList = [];
      for (var i = 0; i < data.length; i++) {
        regionList.push({
          label: data[i],
          value: data[i],
          disabled: this.get('availableRegion').indexOf(data[i]) > -1 ? false : true,
          checked: false
        });
      }
      return regionList;
    },
    checkRegion: function checkRegion() {
      var _this = this;

      var data = ['Africa', 'Asia', 'Europe', 'North America', 'Oceania', 'South America'];
      if (this.get('megamenu.selectedRegion.value')) {
        this.get('config').forEach(function (item) {
          if (item.value === _this.get('megamenu.selectedRegion.value')) {
            Ember.set(item, 'checked', true);
            _this.set('megamenu.selectedRegion', item);
          } else {
            Ember.set(item, 'checked', false);
          }
        });
        for (var i = 0; i < data.length; i++) {
          if (this.get('megamenu.selectedRegion.value') === data[i]) {
            data[i] = data[i].replace(' ', '-');
            this.set(data[i], 'enabled');
          } else if (this.get('availableRegion').indexOf(data[i]) > -1) {
            data[i] = data[i].replace(' ', '-');
            this.set(data[i], 'available');
          } else {
            data[i] = data[i].replace(' ', '-');
            this.set(data[i], '');
          }
        }
      } else {
        for (var _i = 0; _i < data.length; _i++) {
          if (this.get('availableRegion').indexOf(data[_i]) > -1) {
            data[_i] = data[_i].replace(' ', '-');
            this.set(data[_i], 'available');
          } else {
            data[_i] = data[_i].replace(' ', '-');
            this.set(data[_i], '');
          }
        }
        this.get('config').forEach(function (item) {
          Ember.set(item, 'checked', false);
        });
      }
    },

    actions: {
      regionChange: function regionChange(val) {
        var _this2 = this;

        if (this.get('megamenu.selectedRegion.value') === val) {
          this.set('megamenu.selectedRegion', {});
        } else {
          var result = this.get('config').filter(function (item) {
            if (item.value === val && _this2.get('megamenu.selectedRegion.value') !== val) {
              Ember.set(item, 'checked', true);
              _this2.set('megamenu.selectedRegion', item);
            } else {
              Ember.set(item, 'checked', false);
            }
            return true;
          });
          this.set('config', result);
        }
        if (this.get('megamenu.selectedRegion.value') && this.get('routing.currentRouteName') !== 'destinations') {
          this.get('routing').transitionTo('destinations');
        }
        this.checkRegion();
      }
    }
  });
});