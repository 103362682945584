define('aia-travel/helpers/html-safe', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.htmlSafe = htmlSafe;
  function htmlSafe(input /*, hash*/) {
    return new Ember.Handlebars.SafeString(input);
  }

  exports.default = Ember.Helper.helper(htmlSafe);
});