define('aia-travel/helpers/days-inbetween', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.daysInbetween = daysInbetween;
  function daysInbetween(params) {
    if (params[0] && params[1]) {
      var startDate, endDate, date1, date2;
      startDate = params[0];
      endDate = params[1];
      date1 = startDate.split(" ");
      date1 = (0, _moment.default)(date1[1]);
      date2 = endDate.split(" ");
      date2 = (0, _moment.default)(date2[1]);

      //var timeDiff = Math.abs(date2.getTime() - date1.getTime());
      //var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24))+1;
      var returnString = "";
      var diffDays = date2.diff(date1, 'days') + 1;
      if (diffDays === 1) {
        returnString = "1 Day";
      } else {
        returnString = diffDays + " Days";
      }
      return returnString;
      //  return params;
    }
  }

  exports.default = Ember.Helper.helper(daysInbetween);
});