define('aia-travel/controllers/destination', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    scrollToDaY: function scrollToDaY(itineraryID) {
      var itineraryMaxWidth, itineraryContainer, offsetLength, moveWidth, nextOffsetLength, daySelected, scrollPoint, tileWidth, offsetEqual;
      daySelected = Ember.$('.itinerary-schedule-wrapper a[href="#destination-day' + itineraryID + '"]');
      scrollPoint = daySelected.position().left;
      offsetEqual = 4;
      itineraryContainer = Ember.$(".itinerary-schedule-wrapper ul");
      moveWidth = itineraryContainer.width();
      tileWidth = itineraryContainer.find("li:last-child").width();
      itineraryMaxWidth = itineraryContainer.find("li:last-child").position().left + tileWidth - moveWidth - offsetEqual;
      nextOffsetLength = moveWidth - scrollPoint - tileWidth;
      var scrollTo = Ember.$('.tab-pane.active').offset().top - 50;

      Ember.$("html, body").animate({
        "scrollTop": scrollTo + "px"
      }, 1);
      if (nextOffsetLength <= 0) {
        itineraryContainer.animate({ left: nextOffsetLength + "px" }, 500);

        Ember.$(".itinerary-schedule-next").show();
        Ember.$(".itinerary-schedule-dates").removeClass("is-initial");
        if (Math.abs(nextOffsetLength) >= itineraryMaxWidth) {
          Ember.$(".itinerary-schedule-next").hide();
        }
        if (nextOffsetLength >= 0) {
          Ember.$(".itinerary-schedule-dates").addClass("is-initial");
        }
      }
    },
    actions: {
      itineraryNext: function itineraryNext() {
        var itineraryMaxWidth, itineraryContainer, offsetLength, moveWidth, nextOffsetLength;
        itineraryContainer = Ember.$(".itinerary-schedule-wrapper ul");
        moveWidth = itineraryContainer.width();
        itineraryMaxWidth = itineraryContainer.find("li:last-child").position().left + itineraryContainer.find("li:last-child").width() - moveWidth;
        offsetLength = itineraryContainer.position().left;
        nextOffsetLength = offsetLength - moveWidth;
        if (Math.abs(nextOffsetLength) > itineraryMaxWidth) {
          nextOffsetLength = -itineraryMaxWidth;
          Ember.$(".itinerary-schedule-next").hide();
        }
        Ember.$(".itinerary-schedule-dates").removeClass("is-initial");
        itineraryContainer.animate({ left: nextOffsetLength + "px" }, 500);
      },
      itineraryPrev: function itineraryPrev() {

        var itineraryContainer, offsetLength, moveWidth, nextOffsetLength;
        itineraryContainer = Ember.$(".itinerary-schedule-wrapper ul");
        moveWidth = itineraryContainer.width();
        offsetLength = itineraryContainer.position().left;
        nextOffsetLength = offsetLength + moveWidth;
        if (nextOffsetLength >= 0) {
          nextOffsetLength = 0;
          Ember.$(".itinerary-schedule-dates").addClass("is-initial");
        }
        Ember.$(".itinerary-schedule-next").show();
        itineraryContainer.animate({ left: nextOffsetLength + "px" }, 500);
      },
      itineraryView: function itineraryView(itineraryID) {

        var thisModel;
        thisModel = this.get("model").destination;
        thisModel.set("itineraryTab", itineraryID);
        if (Ember.$(".navbar-toggle").is(":visible")) {
          Ember.$("#collapse-itinerary-days").collapse('hide');
        }
        if (itineraryID === "overview") {
          Ember.$(".btn-select").html("Overview");
          Ember.$(".btn-group").find('.btn').html('Overview' + "<span class=\"caret\"></span>");
        } else {
          Ember.$(".btn-select").html("- Day " + itineraryID + " -");
          Ember.$(".btn-group").find('.btn').html('Day ' + itineraryID + "<span class=\"caret\"></span>");
        }
      },
      itineraryChangeDay: function itineraryChangeDay(itineraryID) {
        Ember.$('.itinerary-schedule-wrapper a[href="#destination-day' + itineraryID + '"]').tab("show");
        this.send('itineraryView', itineraryID);
        //
        Ember.$(".btn-group").find('.btn').html('Day ' + itineraryID + "<span class=\"caret\"></span>");
        this.scrollToDaY(itineraryID);
      },
      DownloadTripDetails: function DownloadTripDetails() {

        Ember.$("#brochure-modal-container").show();
        Ember.$('.modal-overlay').fadeIn(500);
      },
      modalClose: function modalClose() {

        Ember.$("#brochure-modal-container").hide();
        Ember.$('.modal-overlay').fadeOut(500);
        location.reload();
      }
    }

  });
});