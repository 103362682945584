define('aia-travel/routes/about', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return this.store.query('generalpage', { reload: true }).then(function (pages) {
        return pages.findBy('pageURLSlug', 'about');
      });
    }
  });
});