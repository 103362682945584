
if (typeof FastBoot === 'undefined') {
  if (!runningTests) {
    require('aia-travel/app')['default'].create({"name":"aia-travel","version":"2.1.2+e4613026"});
  }
}

define('~fastboot/app-factory', ['aia-travel/app', 'aia-travel/config/environment'], function(App, config) {
  App = App['default'];
  config = config['default'];

  return {
    'default': function() {
      return App.create(config.APP);
    }
  };
});

