define('aia-travel/components/aia-header', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    routing: Ember.inject.service('-routing'),
    megamenu: Ember.inject.service(),
    // pages: [{
    //  name: "Destinations",
    //  route: "destinations"
    //  },{
    //  name: "Experts",
    //  route: "experts"
    //  },{
    //  name: "Trip Support",
    //  route: "generalpage"
    //  }],
    updateStickiness: function updateStickiness() {
      //header navigation to shrink on scroll
      this.set('isSticky', Ember.$(window).scrollTop() > 1);
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      Ember.$(window).on('scroll.aia-header', function () {
        return _this.updateStickiness();
      });
    },
    didRender: function didRender() {
      var _this2 = this;

      if (this.get('routing.currentRouteName') === 'destinations') {
        this.set('showMegaMenu', false);
      } else {
        this.clearMegaMenuFilter();
      }
      Ember.$(document).click(function () {
        _this2.set('showMegaMenu', false);
      });

      Ember.$("#hello").click(function (e) {
        e.stopPropagation();
      });
    },
    willDestroyElement: function willDestroyElement() {
      Ember.$(window).off('scroll.aia-header');
    },

    actions: {
      mouseOverLi: function mouseOverLi() {
        if (this.get('routing.currentRouteName') !== 'destinations') {
          this.set('showMegaMenu', true);
        }
      },
      clickDestination: function clickDestination() {
        if (this.get('routing.currentRouteName') === 'destinations') {
          this.clearMegaMenuFilter();
          if (Ember.$("html, body").scrollTop() > 200) {
            Ember.$("html, body").animate({
              "scrollTop": "0px"
            }, 100);
          }
        }
        this.get('routing').transitionTo('destinations');
      },

      /*DownloadTravelCatalog: function() {
          $("#catalog-modal-container").show();
          $('.modal-overlay').fadeIn(500);
      },
      modalClose: function () {
          $("#catalog-modal-container").hide();
          $('.modal-overlay').fadeOut(500);
          location.reload();
            //self.removeClass("hover");
          },*/
      DownloadTravelCatalogHead: function DownloadTravelCatalogHead() {
        Ember.$("#catalog-modal-container").show();
        this.set('megamenu.displayModal', true);
        Ember.$('.modal-overlay').fadeIn(500);
      },
      modalClose: function modalClose() {
        Ember.$("#catalog-modal-container").hide();
        this.set('displayModal', false);
        Ember.$('.modal-overlay').fadeOut(500);
        location.reload();
        //self.removeClass("hover");
      }
    },
    clearMegaMenuFilter: function clearMegaMenuFilter() {
      var _this3 = this;

      if (this.get('megamenu.selectedRegion').value || this.get('megamenu.selectedDuration').value || this.get('megamenu.selectedDate').length) {
        this.set('megamenu.refresh', false);
        this.set('megamenu.selectedRegion', {});
        this.set('megamenu.selectedDuration', {});
        this.set('megamenu.selectedDate', []);
        this.set('megamenu.selectedYear', []);
        Ember.run.next(function () {
          _this3.set('megamenu.refresh', true);
        });
      }
    }
  });
});