define('aia-travel/helpers/aia-data', ['exports', 'aia-travel/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.aiaData = aiaData;
  function aiaData(dataID /*, hash*/) {
    switch (dataID[0]) {

      case 'footer-signup-thankyou':
        return 'Thank you for subscribing! ';
      case 'footer-signup-news-promotions':
        return 'Sign-up for news and promotions';
      // case 'footer-special-program' : return 'A special program brought to you by the American Institute of Architects';
      case 'footer-special-program':
        return 'A special program brought to you by The American Institute of Architects';

      case 'catalog-request-catalog':
        return 'Download Catalog';
      case 'catalog-request-pdf':
        return 'null';
      case 'catalog-form-title':
        return '<h2 class="modal-header">We are thrilled you stopped by! </br> For a digital download (pdf) please enter the information below.</h2>';
      case 'catalog-form-introduction':
        return '<p class="mandatary-hint">All fields required (<span class="asterisk">*</span>)</p>';
      case 'catalog-second-form-headline':
        return '<h2 class="modal-header">Thank you! <a class="pdf-link" href=' + _environment.default.ARCHITECTURAL_ADVENTURES_CATALOG + ' target="_blank">Click here to download PDF catalog.</a></br> To receive our printed catalogs and brochures, please tell us more.</h2>';
      case 'catalog-second-form-introduction':
        return '<p class="mandatary-hint">Fields required (<span class="asterisk">*</span>)</p>';
      case 'catalog-confirmation-headline':
        return '';
      case 'catalog-confirmation-introduction':
        return '<p>Thank you for your interest in Architectural Adventures! As new trips are added, we\u2019ll keep you informed.</p> <p>Click <a class="downloadLink heighlited-text" href=' + _environment.default.ARCHITECTURAL_ADVENTURES_CATALOG + ' target="_blank">here</a> to download the latest PDF catalog.</p>';

      case 'destinations-title':
        return 'Destinations';
      case 'destinations-title-new':
        return 'Welcome';
      // case 'destinations-intro-text': return 'Architectural Adventures carefully selects destinations that stand out for the global significance of their architecture and culture. Whether you’re an architecture buff or an intellectually curious amateur, our trips are designed to expand your understanding and appreciation of the built environment and its place in the wider world.';
      case 'destinations-intro-text':
        return 'Architectural Adventures carefully selects destinations that stand out for the global significance of their architecture and culture. Whether you’re an architectural enthusiast or an intellectually curious traveler, our trips are designed to expand your understanding and appreciation of the built environment and its place in the wider world.';
      case 'destinations-view-experts-card':
        return 'View our experts';
      case 'destinations-detail-button':
        return 'VIEW DETAILS';
      case 'destination-not-found':
        return 'Looks like the destination you\'re looking for doesn\'t exist.';
      // case 'destination-call-to-action' : return '<a href=\"/contact\">Get in touch</a> so you can be the first to know about upcoming trips!';
      case 'destination-call-to-action':
        return 'Book now! Call 1.800.293.5725 &nbsp;&nbsp;&nbsp;M-F 9am-5pm ET or <a href=\"/contact\">Contact Us</a>';

      case 'brochure-form-headline':
        return '<h2 class="modal-header">We are thrilled you stopped by! </br> For a digital download (pdf) please enter the information below.</h2>';
      case 'brochure-form-introduction':
        return '<p class="mandatary-hint">All fields required (<span class="asterisk">*</span>)</p>';
      case 'brochure-second-form-headline':
        return ' ';
      case 'brochure-second-form-introduction':
        return '';
      case 'brochure-confirmation-headline':
        return '';
      case 'brochure-confirmation-introduction':
        return '<p>Thank you for your interest in Architectural Adventures! As new trips are added, we’ll keep you informed.</p>';

      case 'experts-title':
        return 'Experts';
      // case 'experts-intro-text' : return 'Architectural Adventures recruits leading building and design subject matter experts to enhance the experience of every trip. With the guidance of our experts and study leaders, Architectural Adventures travelers go beyond simple sightseeing and discover the historical, cultural, and aesthetic significance of the world’s great sites and structures.';
      case 'experts-intro-text':
        return 'Architectural Adventures selects leading building and design subject matter experts who offer unique insights for each trip. With the guidance of our experts, Architectural Adventures’ travelers go beyond simple sightseeing and discover the historical, cultural, and aesthetic significance of the world’s great sites and structures.';
      case 'expert-not-found':
        return 'Looks like the expert you\'re looking for doesn\'t exist.';

      case '404-title':
        return 'Page not found';
      case '404-message':
        return 'Sorry to say you\'ve lost your way...';

      case 'page-contact-introduction':
        return '';
      case 'page-contact-confirmation':
        return 'Thank you for your interest in Architectural Adventures! <br/> An Architectural Adventures specialist will respond within 1-2 business days.';

      case 'homepage-intro-text':
        return 'Architectural Adventures is the official travel program of the American Institute of Architects, offering first-rate experiences in breathtaking destinations across the globe. In our expert-led, immersive, small-group setting, allow yourself the chance to engage with the world’s most notable architecture, art, and culture.';
    }
  }

  exports.default = Ember.Helper.helper(aiaData);
});