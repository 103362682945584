define('aia-travel/components/isotope-grid', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    actions: {
      flipCardover: function flipCardover(id) {
        var selfie = Ember.$("#flip-container-" + id + " .card-item");
        Ember.$(selfie).addClass('overstate');
      },
      flipCardout: function flipCardout(id) {
        var selfie = Ember.$("#flip-container-" + id + " .card-item");
        Ember.$(selfie).removeClass('overstate');
      },
      flipCard: function flipCard(id) {
        var self, selfWidth, selfHeight, selfTop, selfLeft, selfClone, modalWidth, modalLeft, modalHeight, modalParent;
        var selfie = Ember.$("#flip-container-" + id + " .card-item");
        Ember.$(selfie).removeClass('overstate');
        self = Ember.$("#flip-container-" + id);
        selfWidth = self.width();
        selfHeight = self.height();
        selfTop = self[0].getBoundingClientRect().top;
        selfLeft = self[0].getBoundingClientRect().left;
        Ember.$("#destination-modal-conatiner").css({ "width": selfWidth + "px", "height": selfHeight + "px", "left": selfLeft + "px", "top": selfTop + "px" });
        selfClone = self.find(".destination-meta-info").clone();
        Ember.$("#destination-modal-conatiner").html(selfClone);
        Ember.$("#destination-modal-conatiner").data("id", id);
        if (!self.hasClass("hover")) {
          self.addClass("hover");
          setTimeout(function () {
            Ember.$("#destination-modal-conatiner").show();
            modalParent = self.parent().find(".destination-modal-hidden").show();
            modalWidth = modalParent.find(".modal-content").width();
            modalHeight = modalParent.find(".modal-content").height();
            self.parent().find(".destination-modal-hidden").hide();
            modalLeft = (Ember.$("body").width() - modalWidth) / 2;
            Ember.$('.modal-overlay').fadeIn(500);
            Ember.$("#destination-modal-conatiner").animate({ "width": modalWidth, "left": modalLeft, "top": "84px", "height": modalHeight }, 500, function () {
              setTimeout(function () {
                selfClone = self.parent().find(".destination-modal-hidden").html();
                Ember.$("#destination-modal-conatiner").html(selfClone).addClass("modal fade in");
                Ember.$("#destination-modal-conatiner").removeAttr("style");
              }, 200);
            });
          }, 400);
        }
      },
      flipCardClose: function flipCardClose() {
        var self, selfWidth, selfHeight, selfTop, selfLeft, selfClone, modalWidth, modalLeft, modalHeight, id;
        id = Ember.$("#destination-modal-conatiner").data("id");
        if (id) {
          self = Ember.$("#flip-container-" + id);
          selfWidth = self.width();
          selfHeight = self.height();
          selfTop = self[0].getBoundingClientRect().top;
          selfLeft = self[0].getBoundingClientRect().left;
          selfClone = self.find(".destination-meta-info").clone();
          modalWidth = Ember.$(".modal-dialog").width();
          modalHeight = Ember.$(".modal-dialog").height();
          modalLeft = (Ember.$("body").width() - modalWidth) / 2;
          Ember.$("#destination-modal-conatiner").css({ "width": modalWidth, "left": modalLeft, "top": "84px", "height": modalHeight, "display": "block" }).removeClass("modal fade in").html(selfClone);
          Ember.$("#destination-modal-conatiner").animate({ "width": selfWidth + "px", "height": selfHeight + "px", "left": selfLeft + "px", "top": selfTop + "px" }, 500, function () {
            Ember.$("#destination-modal-conatiner").hide();
            Ember.$('.modal-overlay').fadeOut(500);
            self.removeClass("hover");
          });
        }
      }
    },

    classNames: ['grid'],
    didInsertElement: function didInsertElement() {
      this.$().isotope({
        itemSelector: '.grid-item',
        columnWidth: '.grid-sizer',
        percentPosition: true,
        transitionDuration: '0.1s'
        // options
        //itemSelector: '.grid-item',
        //layoutMode: 'fitRows'
      });
    }
  });
});