define('aia-travel/components/mega-menu', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    megamenu: Ember.inject.service(),
    routing: Ember.inject.service('-routing'),
    init: function init() {
      this._super();
      this.set('availableDate', []);
      this.set('availableRegion', []);
      this.set('availableDuration', []);
    },
    availableData: Ember.computed('model', function () {
      var _this = this;

      if (this.get('model')) {
        this.get('model').forEach(function (data) {
          var startDate = data.get('startDate'),
              endDate = data.get('endDate');

          startDate = new Date(startDate.split(" ")[1]);
          endDate = new Date(endDate.split(" ")[1]);

          var timeDiff = Math.abs(endDate.getTime() - startDate.getTime());
          var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)) + 1;

          startDate = (0, _moment.default)(startDate).format('MM/01/YYYY');
          //endDate = moment(endDate).format('MM/01/YYYY');

          if (_this.get('availableDuration').indexOf(diffDays) <= -1) {
            _this.get('availableDuration').push(diffDays);
          }
          if (_this.get('availableDate').indexOf(startDate) <= -1) {
            _this.get('availableDate').push(startDate);
          }
          // if (this.get('availableDate').indexOf(endDate) <= -1) {
          //   this.get('availableDate').push(endDate);
          // }
          if (_this.get('availableRegion').indexOf(data.get('region')) <= -1) {
            _this.get('availableRegion').push(data.get('region'));
          }
        });
      }
    })
  });
});