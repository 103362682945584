define('aia-travel/routes/foreign-redirect', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    fastboot: Ember.inject.service(),
    beforeModel: function beforeModel() {
      if (this.get('fastboot.isFastBoot')) {
        var resHeaders = this.get('fastboot.response.headers');
        resHeaders.set('Location', this.destination);
        this.set('fastboot.response.statusCode', 302);
      } else {
        window.location.href = this.destination;
      }
    }
  });
});