define('aia-travel/routes/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var set = Ember.set;
  exports.default = Ember.Route.extend({
    // activate: function() {
    //   window.scrollTo(0,0);
    // },
    headData: Ember.inject.service(),
    model: function model() {

      return Ember.RSVP.hash({

        carousel: this.store.findAll('carousel'),
        destinations: this.store.findAll('destination')

      });
    },
    afterModel: function afterModel(model) {
      set(this, 'headData.title', 'Architectural Adventures');
      set(this, 'headData.description', 'Architectural Adventures is the official travel program of the American Institute of Architects, offering first-rate experiences in breathtaking destinations across the globe.');
      set(this, 'headData.image', model.carousel.toArray()[0].get('slider_image'));
    },


    setupController: function setupController(controller, model) {
      this._super(controller, model);
      model.isFastboot = typeof FastBoot === 'undefined' ? true : false;
    }

  });
});