define("aia-travel/helpers/marketo-formrender", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.marketoFormrender = marketoFormrender;

  /* global MktoForms2 */

  function marketoFormrender(params /*, hash*/) {

    MktoForms2.loadForm("//app-sj03.marketo.com", "926-JCZ-725", params, function () {});
  }

  exports.default = Ember.Helper.helper(marketoFormrender);
});