define('aia-travel/router', ['exports', 'aia-travel/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('destinations');
    this.route('destination', { path: 'destinations/:destinationSlug' });

    this.route('expert', { path: 'experts/:expertSlug' });
    this.route('experts');
    this.route('demo-links');
    this.route('json-demo');
    this.route('generalpage', { path: 'page/:pageURLSlug' });
    this.route('about');
    this.route('isdi');
    this.route('ahi');
    this.route('ata');
    this.route('odysseys');
    this.route('goahead');
    this.route('travel-terms');
    this.route('trip-support');
    this.route('contact');
    this.route('fourohfour', { path: "/*path" });

    this.route('destinations/mexico-city', { path: '/gomexico' });
    this.route('poolspa');
    this.route('architectmag');
    this.route('aianv');
    this.route('A17');
    this.route('nola');
    this.route('a17');
    this.route('aiachapter');
    this.route('podcast');
    this.route('spring2019catalog', { path: 'spring2019catalog/:catalog' });

    this.route('updates');
    this.route('aiachapters');
    this.route('nyt');
    this.route('2018');

    this.route('gomiami');
    this.route('gorhine');

    this.route('aba');
    this.route('gojapan');
    this.route('goeasterneurope');
    this.route('gorome');
    this.route('goscandinavia');
    this.route('gobauhaus');
    this.route('godetroit');
    this.route('gocambodia');
    this.route('govienna');
    this.route('gouk');
    this.route('catalog');

    this.route('signup');
    this.route('gomontreal');
    this.route('golisbon');
    this.route('goiberia');
    this.route('learnmore');
  });

  exports.default = Router;
});