define('aia-travel/helpers/years-label', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.datePeriod = datePeriod;
  function datePeriod(params) {
    if (params[0]) {
      var years = params[0],
          result = [years.slice(0, -1).join(', '), years.slice(-1)[0]].join(years.length < 2 ? '' : ' and ');
      return result;
    }
  }
  exports.default = Ember.Helper.helper(datePeriod);
});