define('aia-travel/adapters/carousel', ['exports', 'aia-travel/adapters/application', 'aia-travel/config/environment'], function (exports, _application, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    buildURL: function buildURL() {
      return _environment.default.AIA_DRUPAL_URL + '/carousel?_format=json';
    },
    handleResponse: function handleResponse(status, headers, payload) {

      for (var i = 0; i < payload.length; i++) {
        payload[i].slider_image = payload[i].slider_image.replace(_environment.default.AIA_DRUPAL_IMG_URL, _environment.default.AIA_CLOUDFRONT_IMG_URL);
      }

      return this._super(status, headers, payload);
    }
  });
});