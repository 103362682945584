define('aia-travel/components/mega-menu/duration', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    init: function init() {
      this._super();
      this.set('config', this.makeDurationList());
      this.checkDuration();
    },

    makeDurationList: function makeDurationList() {
      var data = ['1-4', '5-9', '10-14', '15'];
      var durationList = [];
      for (var i = 0; i < 4; i++) {
        var min = data[i].length === 2 ? data[i] : data[i].split('-')[0],
            max = data[i].length === 2 ? null : data[i].split('-')[1];

        var isAvailable = this.isAvailFunction(min, max);

        durationList.push({
          label: data[i].length === 2 ? data[i] + '+ days' : data[i] + ' days',
          value: data[i],
          min: min,
          max: max,
          disabled: isAvailable,
          checked: false
        });
      }
      return durationList;
    },
    isAvailFunction: function isAvailFunction(min, max) {
      var returnObj = this.get('availableDuration').filter(function (item) {
        return item >= parseInt(min) && (max ? item <= parseInt(max) : true);
      });
      return returnObj.length ? false : true;
    },

    checkDuration: function checkDuration() {
      var _this = this;

      if (this.get('megamenu.selectedDuration.value')) {
        this.get('config').forEach(function (item) {
          if (item.value === _this.get('megamenu.selectedDuration.value')) {
            Ember.set(item, 'checked', true);
          } else {
            Ember.set(item, 'checked', false);
          }
        });
      } else {
        this.get('config').forEach(function (item) {
          Ember.set(item, 'checked', false);
        });
      }
    },
    actions: {
      durationChange: function durationChange(val) {
        var _this2 = this;

        if (this.get('megamenu.selectedDuration.value') === val) {
          this.set('megamenu.selectedDuration', {});
        } else {
          var result = this.get('config').filter(function (item) {
            if (item.value === val) {
              Ember.set(item, 'checked', true);
              _this2.set('megamenu.selectedDuration', item);
            } else {
              Ember.set(item, 'checked', false);
            }
            return true;
          });
          this.set('config', result);
        }
        if (this.get('megamenu.selectedDuration.value') && this.get('routing.currentRouteName') !== 'destinations') {
          this.get('routing').transitionTo('destinations');
        }
        this.checkDuration();
      }
    }
  });
});